<template>
  <div>
    <hs-checkbox
      :class="{ 'mt-2': index !== 0 }"
      :name="`custom_fields[${field.slug}]`"
      :value="option"
      v-for="(option, index) in field.options"
      :key="`field_checkbox_${index}`"
      :checked="field.value === option"
      @input="value => $emit('input', value ? value : '')"
    >
      {{ option }}
    </hs-checkbox>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div>
    <hs-radio
      :class="{ 'mt-2': index !== 0 }"
      :name="`custom_fields[${field.slug}]`"
      :value="option"
      v-for="(option, index) in field.options"
      :key="`field_radiobox_${index}`"
      :checked="field.value === option"
      @input="value => $emit('input', value ? value : '')"
    >
      {{ option }}
    </hs-radio>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="accessHistory">
    <div class="bg-white">
      <hs-button variant="link" class="tlw-mt-6 tlw-ml-3 text-purple back-button" @click="backToMemberDetails">
        <hs-icon icon="arrow-left" size="13" />
        <span class="font-size-sm">{{
          $t('sparkmembers.members-details.enrollment-details.access-history.back-btn')
        }}</span>
      </hs-button>
      <h3 class="p-4 mainTitle">
        {{ $t('sparkmembers.members-details.enrollment-details.access-history.main-title') }}
      </h3>
    </div>
    <div class="p-2 p-md-5 mt-3 mt-md-0 d-flex flex-column flex-md-row">
      <GhostLoading v-if="isLoading" />
      <div v-else class="col-12 col-md-8">
        <Details :member="memberData" :enrollment="accessHistoryData" />
        <Activities v-if="activities.length" :activities="activities" />
        <Contents
          v-if="completedContents.length"
          :contents="completedContents"
          :canLoadMore="canLoadMoreContents"
          :isLoading="isLoadingMoreContents"
          :btnLabel="btnLabel"
          @loadMoreContents="fetchContentsData"
        />
      </div>
      <div class="ml-5">
        <h5 class="font-weight-bold mb-4" v-if="timeLineData.length">
          {{ $t('sparkmembers.members-details.enrollment-details.access-history.components.timeline.title') }}
        </h5>
        <Timeline :loading="isLoading" :data="timeLineData" @loadMoreContents="fetchContentsData" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { enrollmentService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export default {
  name: 'AccessHistory',
  components: {
    Details: () => import('./components/Details.vue'),
    Activities: () => import('./components/Activities.vue'),
    Contents: () => import('./components/Contents.vue'),
    Timeline: () => import('./components/Timeline.vue'),
    GhostLoading: () => import('./components/GhostLoading.vue'),
  },
  data() {
    return {
      isLoading: true,
      accessHistoryData: {},
      memberData: {},
      activities: [],
      contents: [],
      canLoadMoreContents: true,
      isLoadingMoreContents: false,
      contentsPage: 0,
    };
  },

  async created() {
    try {
      if (!this.enrollment?.id || !this.member?.id) {
        await Promise.all([await this.getEnrollmentData(), await this.getMemberData()]);
      }
      this.accessHistoryData = this.enrollment;
      this.memberData = {
        ...this.member,
        fullname: this.memberFullname,
      };
      await Promise.all([
        this.fetchAccessHistoryData(),
        this.fetchEnrollmentActivitiesData(),
        this.fetchContentsData(),
      ]);
    } finally {
      this.isLoading = false;
    }
  },
  destroyed() {
    this.selectEnrollment({});
  },
  computed: {
    ...mapState('member', {
      member: state => state.selectedMember,
    }),
    ...mapState('enrollment', {
      enrollment: state => state.selectedEnrollment,
    }),
    btnLabel() {
      const label = this.isLoadingMoreContents ? 'loading' : 'load-more';
      return this.$t(
        `sparkmembers.members-details.enrollment-details.access-history.components.contents.load-more-btn.${label}`
      );
    },
    timeLineData() {
      if (!this.contents.length) return [];
      return this.contents.slice(0, 4);
    },
    completedContents() {
      return this.contents.filter(content => content.completed);
    },
    memberFullname() {
      return `${this.member.first_name} ${this.member.last_name ? this.member.last_name : ''}`;
    },
  },
  methods: {
    ...mapActions('enrollment', ['selectEnrollment', 'getEnrollment']),
    ...mapActions('member', ['getMember']),
    formatDate(date) {
      return dayjs(date).format('DD/MM/YYYY');
    },
    async getMemberData() {
      try {
        this.isLoading = true;
        await this.getMember(this.$route.params.id);
      } catch (error) {
        ToastHelper.dangerMessage(
          this.$t('sparkmembers.members-details.enrollment-details.access-history.toast.member-data-error')
        );
      }
    },
    async getEnrollmentData() {
      try {
        await this.getEnrollment(this.$route.params.enrollmentId);
      } catch (error) {
        ToastHelper.dangerMessage(
          this.$t('sparkmembers.members-details.enrollment-details.access-history.toast.enrollment-data-error')
        );
      }
    },
    async fetchAccessHistoryData() {
      try {
        const params = [{ key: 'page', value: 1 }];
        const {
          lessons_progresses: firstLoadLessonsProgresses,
          current_page,
          total_pages,
        } = await enrollmentService.getEnrollmentLessonProgresses(this.enrollment.id, params);

        if (!firstLoadLessonsProgresses.length) return;

        this.accessHistoryData.lastAccessDate = this.formatDate(firstLoadLessonsProgresses[0].updated_at);

        if (total_pages > current_page) {
          const params = [{ key: 'page', value: total_pages }];
          const {
            lessons_progresses: lastLoadLessonsProgresses,
          } = await enrollmentService.getEnrollmentLessonProgresses(this.enrollment.id, params);
          this.accessHistoryData.firstAccessDate = this.formatDate(
            lastLoadLessonsProgresses[lastLoadLessonsProgresses.length - 1].created_at
          );
        } else {
          this.accessHistoryData.firstAccessDate = this.formatDate(
            firstLoadLessonsProgresses[firstLoadLessonsProgresses.length - 1].created_at
          );
        }
      } catch (error) {
        ToastHelper.dangerMessage(
          this.$t('sparkmembers.members-details.enrollment-details.access-history.toast.access-data-error')
        );
      }
    },
    async fetchEnrollmentActivitiesData() {
      try {
        const { activities } = await enrollmentService.getEnrollmentActivities(this.enrollment.id);
        const processedActivities = await Promise.all(
          activities.map(async activity => {
            const params = [{ key: 'lesson_id', value: activity.exam_lesson.id }];
            if (activity.total_exam_answers_approved || activity.total_exam_answers_disapproved) {
              const { lessons_progresses } = await enrollmentService.getEnrollmentLessonProgresses(
                this.enrollment.id,
                params
              );
              if (!lessons_progresses.length) return null;
              return {
                ...activity,
                exam_answers: lessons_progresses[0],
                updated_at: new Date(lessons_progresses[0].updated_at),
                updated_at_formatted: dayjs(lessons_progresses[0].updated_at).format('DD/MM/YYYY [às] HH:mm'),
              };
            }
            return null;
          })
        );

        this.activities = processedActivities
          .filter(activity => activity !== null)
          .sort((a, b) => b.updated_at - a.updated_at);
      } catch (error) {
        ToastHelper.dangerMessage(
          this.$t('sparkmembers.members-details.enrollment-details.access-history.toast.activities-data-error')
        );
      }
    },
    async fetchContentsData() {
      try {
        this.isLoadingMoreContents = true;
        const params = [{ key: 'per_page', value: 10 }, { key: 'page', value: this.contentsPage + 1 }];
        const { lessons_progresses, current_page, total_pages } = await enrollmentService.getEnrollmentLessonProgresses(
          this.enrollment.id,
          params
        );
        this.contents.push(...lessons_progresses);
        this.contentsPage = current_page;
        this.canLoadMoreContents = current_page < total_pages;
      } catch (error) {
        ToastHelper.dangerMessage(
          this.$t('sparkmembers.members-details.enrollment-details.access-history.toast.contents-data-error')
        );
      } finally {
        this.isLoadingMoreContents = false;
      }
    },
    backToMemberDetails() {
      this.$router.push({ name: 'MemberDetail', params: { id: this.member.id } });
    },
  },
};
</script>
<style lang="scss">
.accessHistory {
  .mainTitle {
    font-weight: 600;
    font-size: 20px;
  }
}
</style>

<template>
  <section class="flex-fill d-flex flex-column justify-content-center align-items-center bg-transparent mt-5">
    <b-img-lazy :src="noFilteredDataImg"></b-img-lazy>
    <h3 class="text-cherry text-center my-4 font-weight-bold">
      {{ $t('sparkmembers.members-list.no-filtered-members-warning.title') }}
    </h3>
    <div>
      <p class="text-center">{{ $t('sparkmembers.members-list.no-filtered-members-warning.p1') }}</p>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      noFilteredDataImg: require('@/assets/images/SparkMembers/MembersList/components/NoFilteredMembersWarning/no_filtered_data.svg'),
    };
  },
};
</script>









































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

@Component
export default class MemberActions extends Vue {
  @Prop({ required: true }) member!: any;
  @Prop({ default: 20 }) sizeIcon!: number;

  @Ref() messageCreatorModal!: any;

  memberArray: any[] = [];
  showConfirmBox!: any;

  get memberProducts() {
    const { enrollments } = this.member.registrations[0];
    return enrollments.reduce((acc: any[], { status, school_product: { id, title } }: any) => {
      status === 'active' && acc.push({ id, title });
      return acc;
    }, []);
  }

  get canSendMessage() {
    return this.$ACL.canMessagesPage();
  }

  sendMessage() {
    this.$root.$emit('onShowSendMessageMemberModal', [this.member]);
  }

  assignProduct() {
    this.$root.$emit('onShowAssignProductMemberModal', [this.member]);
  }

  desactiveMembers() {
    this.$root.$emit('onShowDesactiveMemberModal', { members: [this.member], products: this.memberProducts });
  }

  deleteMember() {
    this.$root.$emit('onShowDeleteMemberModal', [this.member]);
  }

  addTag() {
    this.$root.$emit('onShowAddTagModal', [this.member]);
  }
}

<template>
  <div
    v-if="checkIfProfilePictureIsDefault"
    data-testid="profile-name"
    class="profile rounded-circle"
    :class="customClass"
  >
    {{ getMemberInitials }}
  </div>
  <b-img-lazy
    v-else
    data-testid="profile-picture"
    class="rounded-circle"
    :src="coverImageUrl"
    :class="customClass"
    :alt="name"
  />
</template>
<script>
export default {
  name: 'MemberProfilePicture',
  props: {
    name: {
      type: String,
    },
    coverImageUrl: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    checkIfProfilePictureIsDefault() {
      return this.coverImageUrl.includes('/default.jpeg');
    },
    getMemberInitials() {
      const names = this.name.split(/\s+/);
      return `${names[0].charAt(0)}${names.length > 1 ? names[names.length - 1].charAt(0) : ''}`.toUpperCase();
    },
  },
};
</script>
<style lang="scss" scoped>
.profile {
  background-color: #7427f1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
}
</style>

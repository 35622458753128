<template>
  <div>
    <hs-multiselect
      searchable
      :showLabels="false"
      :options="field.options"
      :placeholder="field.placeholder"
      v-model="field.value"
      @input="value => $emit('input', value)"
    />
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
};
</script>

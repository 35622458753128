<template>
  <div class="bg-white rounded-lg py-4 px-5 mt-5">
    <b-form @submit.prevent="onSubmit">
      <div class="row">
        <div class="col-12 col-lg-6" v-for="field in fields" :key="`field_${field.title}`">
          <hs-group>
            <label>{{ field.title }}{{ field.required ? '*' : '' }}</label>
            <component :is="`field-${field.type}`" :field="field" v-model="customs_fields[field.slug]" />
          </hs-group>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-5">
        <hs-button :variant="isSaving ? 'light' : 'primary'" :disabled="isSaving" type="submit">
          {{ $t(`sparkmembers.members-details.personal.form.${isSaving ? 'saving' : 'save'}`) }}
        </hs-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { studentService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import RadioBox from './Fields/RadioBox';
import CheckBox from './Fields/CheckBox';
import Text from './Fields/Text';
import Textarea from './Fields/Textarea';
import Date from './Fields/Date';
import Select from './Fields/Select';
import Number from './Fields/Number';

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
      fields: [],
      customs_fields: {},
    };
  },
  components: {
    'field-radiobox': RadioBox,
    'field-checkbox': CheckBox,
    'field-text': Text,
    'field-textarea': Textarea,
    'field-date': Date,
    'field-select': Select,
    'field-number': Number,
  },
  methods: {
    ...mapActions('member', ['selectMember']),
    onSubmit() {
      this.isSaving = true;
      studentService
        .updateStudent(this.form.id, {
          ...this.form,
          custom_attributes_values: this.customs_fields,
        })
        .then(() => {
          ToastHelper.successMessage(this.$t('sparkmembers.members-details.personal.toast.custom-fields.success'));
          studentService.getStudent(this.form.id).then(res => this.selectMember({ ...res.data }));
        })
        .catch(() =>
          ToastHelper.dangerMessage(this.$t('sparkmembers.members-details.personal.toast.custom-fields.error'))
        )
        .finally(() => (this.isSaving = false));
    },
    normalizeFields() {
      let fields = {};
      this.form.custom_attributes_values[0].values.map(field => (fields[field.slug] = field.value));
      return fields;
    },
  },
  mounted() {
    this.fields = this.form.custom_attributes.fields.sort((a, b) => a.order - b.order);
    this.customs_fields = this.form.custom_attributes_values.length > 0 ? this.normalizeFields() : {};
  },
};
</script>











































































































































































import { Component, Mixins } from 'vue-property-decorator';
import { studentService, channelService } from '@/services';
import { namespace } from 'vuex-class';
import GeneralHelper from '@/shared/helpers/general';
import TrackingHelper from '@/shared/helpers/tracking';
import MButton from '@/shared/components/MButton.vue';
import Loading from '@/components/Loading.vue';
import Confirm from '@/shared/mixins/Confirm';
import ToastHelper from '@/shared/helpers/toast';
import MemberActions from '@/sparkmembers/views/MembersList/components/MemberActions.vue';
import { Member, Tag } from '@/types/members';
import dayjs from 'dayjs';
import _ from 'lodash';
import { sortLocaleWithKey } from '@/libs/strings';

const MemberModule = namespace('member');
const SchoolModule = namespace('school');

@Component({
  components: {
    Loading,
    Timeline: () => import('./components/Timeline/index.vue'),
    MButton,
    MemberActions,
    AddTagModal: () => import('@/sparkmembers/views/MembersList/components/AddTagModal.vue'),
    AssignProductModal: () => import('@/sparkmembers/views/MembersList/components/AssignProductModal.vue'),
    DesactiveEnrollmentModal: () => import('@/sparkmembers/views/MembersList/components/DesactiveEnrollmentModal.vue'),
    MessageCreatorModal: () =>
      import('@/views/Messages/views/List/components/Tab/Messages/components/MessageCreatorModal.vue'),
    MemberProfilePicture: () => import('@/components/MemberProfilePicture.vue'),
    Products: () => import('./components/Products/index.vue'),
  },
  filters: {
    date(value) {
      return dayjs(value).format('DD/MM/YYYY');
    },
    time(value) {
      return dayjs(value).format('HH:mm');
    },
    currency(value) {
      return GeneralHelper.currency(value / 100, false);
    },
  },
})
export default class Resume extends Mixins(Confirm) {
  @MemberModule.State('selectedMember') member!: Member;
  @SchoolModule.State selectedSchool!: any;

  @MemberModule.Action selectMember!: (member: Member) => void;
  @MemberModule.Action updateMember!: (member: Member) => void;
  @MemberModule.Action deleteMembers!: (member: Member[]) => void;

  loadingAnalytics: boolean = true;
  dataAnalitycs: any = {};
  enrollments: any = [{}, {}];
  enrollmentsPageData: any = {};
  total: number = 0;

  desactiveMembers: any = {};

  showConfirmBox!: any;
  showAssignProductModal: boolean = false;
  showMyTagsModal: boolean = false;
  showAddTagModal: boolean = false;
  hideAmountTemporarily: boolean = false;

  get fullname() {
    return `${this.member.first_name} ${this.member.last_name ? this.member.last_name : ''}`;
  }

  async mounted() {
    await this.loadAnalitycs();

    this.$root.$on('onShowSendMessageMemberModal', this.openSendMessageModal);
    this.$root.$on('onShowAssignProductMemberModal', this.openAssignProductModal);
    this.$root.$on('onShowDesactiveMemberModal', this.desactiveMember);
    this.$root.$on('onShowDeleteMemberModal', this.removeMember);
    this.$root.$on('onShowAddTagModal', this.openAddTagModal);
  }

  getBadgeVariant(status) {
    const types = ['active', 'alumnus', 'lead'];
    const variants = ['success', 'cherry', 'secondary'];
    return variants[types.indexOf(status)];
  }

  async loadAnalitycs() {
    try {
      this.loadingAnalytics = true;

      const res = await studentService.analitycs(this.member.id);
      this.dataAnalitycs = res;
      this.enrollmentsPageData = res.enrollmentsPageData;
      this.enrollments = res.enrollments;
      res.orders.map(order => {
        if (order.status === 'confirmed') this.total += order.amount_to_pay;
      });
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    } finally {
      this.loadingAnalytics = false;
    }
  }

  openSendMessageModal() {
    this.$bvModal.show('message-creator-modal');
  }

  async createMessage({ channel, message }: any) {
    try {
      const { id } = await channelService.create(channel);
      await channelService.createMessage({ ...message, channel_id: id });
      this.trackingMessageSent(message);
      ToastHelper.successMessage(this.$t('messages.views.list.components.message-creator-modal.toast.success'));
    } catch (error) {
      ToastHelper.dangerMessage(
        this.$t('messages.views.list.components.message-creator-modal.toast.error-send-mensage')
      );
    }
  }

  openAssignProductModal(open: boolean = true) {
    this.showAssignProductModal = open;
  }

  async desactiveMember() {
    const products = this.enrollments.map(enroll => ({
      id: enroll.school_product.id,
      title: enroll.school_product.title,
    }));

    this.desactiveMembers = {
      members: [this.member],
      products: sortLocaleWithKey(products, 'title'),
    };

    await this.$nextTick();
    this.$bvModal.show('desactive-enrollment-modal');
  }

  async showConfirmBoxToDeleteMembers() {
    return await this.showConfirmBox({
      contentTitle: this.$t('sparkmembers.members-list.delete-member-modal.title'),
      contentDescription: this.$t('sparkmembers.members-list.delete-member-modal.description'),
      okTitle: this.$t('sparkmembers.members-list.delete-member-modal.buttons.confirm'),
      cancelTitle: this.$t('sparkmembers.members-list.delete-member-modal.buttons.cancel'),
      variant: 'cherry',
      icon: 'info-circle',
    });
  }

  async removeMember(members: Member[]) {
    const confirm = await this.showConfirmBoxToDeleteMembers();

    if (confirm) {
      try {
        await this.deleteMembers(members);
        this.$router.push({ name: 'MembersList' });
        ToastHelper.successMessage(this.$t('sparkmembers.members-list.delete-member-modal.toast-success'));
        this.trackingMemberDelete();
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.members-list.delete-member-modal.toast-error'));
      }
    }
  }

  async confirmBoxToRemoveTag(tag: Tag) {
    return await this.showConfirmBox({
      contentTitle: this.$t(`sparkmembers.members-list.tags-modal.delete-tag-modal.title`),
      contentDescription: this.$t(`sparkmembers.members-list.tags-modal.delete-tag-modal.subtitle-member`, {
        tag: tag.name,
        member: this.fullname,
      }),
      okTitle: this.$t(`sparkmembers.members-list.tags-modal.delete-tag-modal.buttons.ok`),
      cancelTitle: this.$t(`sparkmembers.members-list.tags-modal.delete-tag-modal.buttons.cancel`),
      variant: 'cherry',
      icon: 'trash-alt',
    });
  }

  async removeTag(tag: Tag) {
    const confirm = await this.confirmBoxToRemoveTag(tag);

    if (!confirm) return;

    try {
      const payload = {
        ...this.member,
        categories: this.member.categories.filter(category => category.id !== tag.id),
        category_ids: this.member.categories.reduce(
          (ids: number[], category: Tag) => (category.id !== tag.id && ids.push(category.id), ids),
          []
        ),
      };

      await this.updateMember(payload);

      ToastHelper.successMessage(this.$t(`sparkmembers.members-list.tags-modal.toast.delete-tag-success`));
    } catch (error) {
      ToastHelper.dangerMessage(this.$t(`sparkmembers.members-list.tags-modal.toast.delete-tag-error`));
    }
  }

  openAddTagModal() {
    this.showAddTagModal = true;
  }

  async reloadMember() {
    try {
      this.loadingAnalytics = true;
      const { data } = await studentService.getStudent(this.$route.params.id);
      this.selectMember({ ...this.member, ...data });
      await this.loadAnalitycs();
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('sparkmembers.members-details.toast.not-found'));
    } finally {
      this.loadingAnalytics = false;
    }
  }

  trackingMessageSent(message: { text: string }) {
    const anexo = message.text.search('<img') !== -1;
    TrackingHelper.trackMemberMessageSent(
      'Member Details Page',
      this.selectedSchool.email,
      this.selectedSchool.id,
      1,
      anexo
    );
  }

  trackingMemberDelete() {
    TrackingHelper.trackMemberDeleted('Member Details Page', this.selectedSchool.email, this.selectedSchool.id, 1);
  }
}

<template>
  <div class="row">
    <div class="col-12 col-lg-8 mb-4">
      <div class="bg-white rounded-lg py-4 px-5 mb-5">
        <b-form @submit.prevent="onSubmit">
          <div class="row">
            <div class="col-12 col-lg-6">
              <hs-group>
                <label>{{ $t('sparkmembers.members-details.personal.form.first-name') }}</label>
                <hs-input name="first_name" type="text" v-model="form.first_name" :disabled="isSaving" />
              </hs-group>
            </div>
            <div class="col-12 col-lg-6">
              <hs-group>
                <label>{{ $t('sparkmembers.members-details.personal.form.last-name') }}</label>
                <hs-input name="last_name" type="text" v-model="form.last_name" :disabled="isSaving" />
              </hs-group>
            </div>
            <div class="col-12 col-lg-6">
              <hs-group>
                <label>CPF</label>
                <b-input name="cpf" type="tel" v-model="form.cpf" :disabled="isSaving" v-mask="['###.###.###-##']" />
              </hs-group>
            </div>
            <div class="col-12 col-lg-6">
              <hs-group>
                <label>{{ $t('sparkmembers.members-details.personal.form.birthdate') }}</label>
                <hs-date
                  v-model="form.born_at"
                  :label-no-date-selected="$t('date.format')"
                  :disabled="isSaving"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                />
              </hs-group>
            </div>
            <div class="col-12 col-lg-6">
              <hs-group>
                <label>{{ $t('sparkmembers.members-details.personal.form.email') }}</label>
                <hs-input name="email" type="email" v-model="form.email" :disabled="isSaving" />
              </hs-group>
            </div>
            <div class="col-12 col-lg-6">
              <hs-group>
                <label>{{ $t('sparkmembers.members-details.personal.form.phone') }}</label>
                <b-input
                  name="phone"
                  type="tel"
                  v-model="form.phone"
                  :disabled="isSaving"
                  v-mask="['(##) ####-####', '(##) #####-####']"
                />
              </hs-group>
            </div>
          </div>
          <div class="d-flex w-100 justify-content-end mt-5">
            <hs-button :variant="isSaving ? 'light' : 'primary'" :disabled="isSaving" type="submit">
              {{ $t(`sparkmembers.members-details.personal.form.${isSaving ? 'saving' : 'save'}`) }}
            </hs-button>
          </div>
        </b-form>
      </div>

      <div class="bg-white rounded-lg py-4 px-5">
        <b-form @submit.prevent="onSubmit">
          <div class="row">
            <div class="col-12 col-lg-8">
              <hs-group>
                <label>{{ $t('sparkmembers.members-details.personal.form.address.street') }}*</label>
                <hs-input
                  name="street"
                  type="text"
                  v-model="address_attributes.street"
                  :disabled="isSaving"
                  @blur="$v.address_attributes.street.$touch()"
                  :state="!$v.address_attributes.street.$error ? null : false"
                >
                  <template slot="feedback" v-if="$v.address_attributes.street.$error">
                    <hs-form-invalid-feedback :state="false" v-if="!$v.address_attributes.street.required">
                      {{ $t('validations.required') }}
                    </hs-form-invalid-feedback>
                  </template>
                </hs-input>
              </hs-group>
            </div>
            <div class="col-12 col-lg-4">
              <hs-group>
                <label>{{ $t('sparkmembers.members-details.personal.form.address.number') }}*</label>
                <hs-input
                  name="number"
                  type="text"
                  v-model="address_attributes.number"
                  :disabled="isSaving"
                  @blur="$v.address_attributes.number.$touch()"
                  :state="!$v.address_attributes.number.$error ? null : false"
                >
                  <template slot="feedback" v-if="$v.address_attributes.number.$error">
                    <hs-form-invalid-feedback :state="false" v-if="!$v.address_attributes.number.required">
                      {{ $t('validations.required') }}
                    </hs-form-invalid-feedback>
                  </template>
                </hs-input>
              </hs-group>
            </div>
            <div class="col-12 col-lg-6">
              <hs-group>
                <label>{{ $t('sparkmembers.members-details.personal.form.address.complement') }}</label>
                <hs-input name="complement" type="text" v-model="address_attributes.complement" :disabled="isSaving" />
              </hs-group>
            </div>
            <div class="col-12 col-lg-6">
              <hs-group>
                <label>{{ $t('sparkmembers.members-details.personal.form.address.district') }}*</label>
                <hs-input
                  name="district"
                  type="text"
                  v-model="address_attributes.district"
                  :disabled="isSaving"
                  @blur="$v.address_attributes.district.$touch()"
                  :state="!$v.address_attributes.district.$error ? null : false"
                >
                  <template slot="feedback" v-if="$v.address_attributes.district.$error">
                    <hs-form-invalid-feedback :state="false" v-if="!$v.address_attributes.district.required">
                      {{ $t('validations.required') }}
                    </hs-form-invalid-feedback>
                  </template>
                </hs-input>
              </hs-group>
            </div>
            <div class="col-12 col-lg-6">
              <hs-group>
                <label>{{ $t('sparkmembers.members-details.personal.form.address.city') }}*</label>
                <hs-input
                  name="city"
                  type="text"
                  v-model="address_attributes.city"
                  :disabled="isSaving"
                  @blur="$v.address_attributes.city.$touch()"
                  :state="!$v.address_attributes.city.$error ? null : false"
                >
                  <template slot="feedback" v-if="$v.address_attributes.city.$error">
                    <hs-form-invalid-feedback :state="false" v-if="!$v.address_attributes.city.required">
                      {{ $t('validations.required') }}
                    </hs-form-invalid-feedback>
                  </template>
                </hs-input>
              </hs-group>
            </div>
            <div class="col-12 col-lg-6">
              <hs-group>
                <label>{{ $t('sparkmembers.members-details.personal.form.address.state') }}*</label>
                <hs-multiselect
                  searchable
                  :showLabels="false"
                  :options="states"
                  track-by="code"
                  label="name"
                  placeholder="Selecione um estado"
                  v-model="address_attributes.state"
                  :disabled="isSaving"
                />
                <template slot="feedback" v-if="!$v.address_attributes.state.$error">
                  <hs-form-invalid-feedback :state="false" v-if="!$v.address_attributes.state.required">
                    {{ $t('validations.required') }}
                  </hs-form-invalid-feedback>
                </template>
              </hs-group>
            </div>
            <div class="col-12 col-lg-6">
              <hs-group>
                <label>{{ $t('sparkmembers.members-details.personal.form.address.zip-code') }}*</label>
                <b-input
                  name="zip_code"
                  type="tel"
                  v-model="address_attributes.zip_code"
                  :disabled="isSaving"
                  v-mask="['##.###-###']"
                  @blur="$v.address_attributes.zip_code.$touch()"
                  :state="!$v.address_attributes.zip_code.$error ? null : false"
                >
                  <template slot="feedback" v-if="$v.address_attributes.zip_code.$error">
                    <hs-form-invalid-feedback :state="false" v-if="!$v.address_attributes.zip_code.required">
                      {{ $t('validations.required') }}
                    </hs-form-invalid-feedback>
                  </template>
                </b-input>
              </hs-group>
            </div>
          </div>
          <div class="d-flex w-100 justify-content-end mt-5">
            <hs-button
              :variant="$v.address_attributes.$invalid || isSaving ? 'light' : 'primary'"
              :disabled="$v.address_attributes.$invalid || isSaving"
              type="submit"
            >
              {{ $t(`sparkmembers.members-details.personal.form.${isSaving ? 'saving' : 'save'}`) }}
            </hs-button>
          </div>
        </b-form>
      </div>

      <CustomFieldsForm
        :form="form"
        v-if="form.custom_attributes.school_id && form.custom_attributes.fields.length > 0"
      />
    </div>
    <div class="col-12 col-lg-4">
      <div class="bg-white rounded-lg p-4">
        <template v-if="!changePassword">
          <h5 class="font-weight-bold">{{ $t('sparkmembers.members-details.personal.form.password.title') }}</h5>
          <p class="font-size-sm my-3">
            {{ $t('sparkmembers.members-details.personal.form.password.description') }}
          </p>
          <div class="d-flex">
            <hs-button variant="outline-secondary" class="flex-fill mr-2" @click="toggleChangePassword()">
              {{ $t('sparkmembers.members-details.personal.form.password.reset-password') }}
            </hs-button>
            <hs-button
              :variant="isSending ? 'light' : 'outline-secondary'"
              class="flex-fill ml-2"
              @click="() => $bvModal.show('send-email-modal')"
            >
              {{ $t(`sparkmembers.members-details.personal.form.password.${isSending ? 'sending' : 'send-email'}`) }}
            </hs-button>
          </div>
        </template>
        <template v-else>
          <b-form @submit.prevent="onSubmitPassword">
            <hs-group>
              <label>
                {{ $t('sparkmembers.members-details.personal.form.password.input.new-password') }}
              </label>
              <hs-input
                name="password"
                type="password"
                v-model="formPassword.password"
                @blur="$v.formPassword.password.$touch()"
                :state="!$v.formPassword.password.$error ? null : false"
              >
                <template slot="feedback" v-if="$v.formPassword.password.$error">
                  <hs-form-invalid-feedback :state="false" v-if="!$v.formPassword.password.required">
                    {{ $t('validations.required') }}
                  </hs-form-invalid-feedback>
                </template>
              </hs-input>
            </hs-group>

            <hs-group>
              <label>
                {{ $t('sparkmembers.members-details.personal.form.password.input.repeat-new-password') }}
              </label>
              <hs-input
                name="password_confirmation"
                type="password"
                v-model="formPassword.password_confirmation"
                @blur="$v.formPassword.password_confirmation.$touch()"
                :state="!$v.formPassword.password_confirmation.$error ? null : false"
              >
                <template slot="feedback" v-if="$v.formPassword.password_confirmation.$error">
                  <hs-form-invalid-feedback :state="false" v-if="!$v.formPassword.password_confirmation.required">
                    {{ $t('validations.required') }}
                  </hs-form-invalid-feedback>
                  <hs-form-invalid-feedback :state="false" v-if="!$v.formPassword.password_confirmation.sameAsPassword">
                    {{ $t('validations.same-as-password') }}
                  </hs-form-invalid-feedback>
                </template>
              </hs-input>
            </hs-group>

            <div class="d-flex justify-content-end">
              <hs-button variant="outline-secondary" class="mr-2" @click="toggleChangePassword(false)">
                {{ $t('actions.back') }}
              </hs-button>
              <hs-button
                :variant="$v.formPassword.$invalid || isChanging ? 'light' : 'primary'"
                :disabled="$v.formPassword.$invalid || isChanging"
                class=" ml-2"
                type="submit"
              >
                {{
                  $t(
                    `sparkmembers.members-details.personal.form.password.${isChanging ? 'reseting' : 'reset-password'}`
                  )
                }}
              </hs-button>
            </div>
          </b-form>
        </template>
      </div>
    </div>
    <hsConfirmModal
      id="send-email-modal"
      icon="envelope"
      variant="primary"
      :customTitle="$t('sparkmembers.members-details.personal.alert.send-email.title')"
      :description="$t('sparkmembers.members-details.personal.alert.send-email.description')"
      :ok-title="$t('sparkmembers.members-details.personal.alert.send-email.confirm-button')"
      :cancel-title="$t('sparkmembers.members-details.personal.alert.send-email.cancel-button')"
      @ok="onSendEmail"
    />
  </div>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators';
import { mapState, mapActions } from 'vuex';
import { studentService } from '@/services';
import { mask } from 'vue-the-mask';
import { hsConfirmModal } from '@/components';
import ToastHelper from '@/shared/helpers/toast';
import CustomFieldsForm from './components/CustomFieldsForm/index';
import get from 'lodash/get';

export default {
  directives: { mask },
  data() {
    return {
      isSaving: false,
      isSending: false,
      isChanging: false,
      address_attributes: {},
      formPassword: {
        password: null,
        password_confirmation: null,
      },
      states: [
        { code: 'AC', name: 'Acre' },
        { code: 'AL', name: 'Alagoas' },
        { code: 'AM', name: 'Amazonas' },
        { code: 'AP', name: 'Amapá' },
        { code: 'BA', name: 'Bahia' },
        { code: 'CE', name: 'Ceará' },
        { code: 'DF', name: 'Distrito Federal' },
        { code: 'ES', name: 'Espírito Santo' },
        { code: 'GO', name: 'Goiás' },
        { code: 'MA', name: 'Maranhão' },
        { code: 'MG', name: 'Minas Gerais' },
        { code: 'MS', name: 'Mato Grosso do Sul' },
        { code: 'MT', name: 'Mato Grosso' },
        { code: 'PA', name: 'Pará' },
        { code: 'PB', name: 'Paraíba' },
        { code: 'PE', name: 'Pernambuco' },
        { code: 'PI', name: 'Piauí' },
        { code: 'PR', name: 'Paraná' },
        { code: 'RJ', name: 'Rio de Janeiro' },
        { code: 'RN', name: 'Rio Grande do Norte' },
        { code: 'RO', name: 'Rondônia' },
        { code: 'RR', name: 'Roraima' },
        { code: 'RS', name: 'Rio Grande do Sul' },
        { code: 'SC', name: 'Santa Catarina' },
        { code: 'SE', name: 'Sergipe' },
        { code: 'SP', name: 'São Paulo' },
        { code: 'TO', name: 'Tocantins' },
      ],
      changePassword: false,
    };
  },

  components: {
    hsConfirmModal,
    CustomFieldsForm,
  },

  validations: {
    address_attributes: {
      street: {
        required,
      },
      number: {
        required,
      },
      district: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
      zip_code: {
        required,
      },
    },
    formPassword: {
      password: {
        required,
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },

  computed: {
    ...mapState('member', {
      member: state => state.selectedMember,
    }),
    ...mapState('school', ['selectedSchool']),
    form() {
      return { ...this.member };
    },
  },

  methods: {
    ...mapActions('member', ['updateMember']),
    async onSubmit() {
      try {
        this.isSaving = true;

        const payload = { ...this.form };

        if (this.address_attributes.street) {
          payload.address_attributes = {
            ...this.address_attributes,
            state: get(this.address_attributes, 'state.code', null),
          };
        }
        await this.updateMember(payload);
        ToastHelper.successMessage(this.$t('sparkmembers.members-details.personal.toast.member.success'));
      } catch (error) {
        if (error?.response?.data?.errors?.email) {
          return ToastHelper.dangerMessage(this.$t('sparkmembers.members-details.personal.toast.email.taken'));
        }
        ToastHelper.dangerMessage(this.$t('sparkmembers.members-details.personal.toast.member.error'));
      } finally {
        this.isSaving = false;
      }
    },

    toggleChangePassword(change = true) {
      this.changePassword = change;
      this.formPassword.password = null;
      this.formPassword.password_confirmation = null;
    },

    async onSubmitPassword() {
      try {
        this.isChanging = true;

        const payload = {
          ...this.form,
          ...this.formPassword,
        };

        await this.updateMember(payload);

        ToastHelper.successMessage(this.$t('sparkmembers.members-details.personal.toast.password.reset.success'));
        this.changePassword = false;
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.members-details.personal.toast.password.reset.error'));
      } finally {
        this.isChanging = false;
      }
    },

    onSendEmail() {
      this.isSending = true;
      studentService
        .password(this.member, this.selectedSchool)
        .then(() =>
          ToastHelper.successMessage(this.$t('sparkmembers.members-details.personal.toast.password.email.success'))
        )
        .catch(() =>
          ToastHelper.dangerMessage(this.$t('sparkmembers.members-details.personal.toast.password.email.error'))
        )
        .finally(() => (this.isSending = false));
    },
  },

  mounted() {
    this.address_attributes = {
      ...this.form.address,
      state: this.form.address ? this.states.filter(state => state.code === this.form.address.state) : null,
    };
  },
};
</script>

































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { BTable } from 'bootstrap-vue';
import MemberActions from './MemberActions.vue';
import { Member } from '@/types/members';
import MemberProfilePicture from '@/components/MemberProfilePicture.vue';

const MemberModule = namespace('member');

@Component({
  components: { MemberActions, MemberProfilePicture },
})
export default class MembersTable extends Vue {
  @Prop() members!: Array<any>;

  @MemberModule.State sidebar!: any;
  @MemberModule.State selectedMember!: any;
  @MemberModule.Action toggleSidebar!: (sidebar: any) => void;
  @MemberModule.Action selectMember!: (member: any) => void;

  @Ref('membersTable') readonly membersTable!: BTable;

  selectedMembers: Member[] = [];
  selectedItems: number = 0;
  fields: Array<any> = [
    { key: 'check', label: '', thClass: 'th-checkbox' },
    { key: 'first_name', label: this.$root.$t('sparkmembers.members-list.members-table.fields.name'), sortable: true },
    { key: 'email', label: this.$root.$t('sparkmembers.members-list.members-table.fields.email'), sortable: true },
    {
      key: 'last_purchase',
      label: this.$root.$t('sparkmembers.members-list.members-table.fields.last-purchase'),
      sortable: true,
    },
    {
      key: 'created_at',
      label: this.$root.$t('sparkmembers.members-list.members-table.fields.created-at'),
      sortable: true,
      thClass: 'text-nowrap',
    },
    { key: 'actions', label: '', sortable: false },
  ];

  toggle(member: any, index: any, event: any) {
    this.toggleSidebar({
      open: this.selectedMember && this.selectedMember.id === member.id && this.sidebar.open ? false : true,
    });
    this.selectMember(member);
    this.setRowClickedClass(event.target.closest('tr'));
  }

  setRowClickedClass(row: any) {
    this.removeAllRowClickedClass();

    if (!this.sidebar.open) {
      row.classList.remove('clicked');
    } else {
      row.classList.add('clicked');
    }
  }

  removeAllRowClickedClass() {
    const rows = Array.from(document.querySelectorAll('tr'));
    rows.forEach(row => {
      row.classList.remove('clicked', 'table-info-light');
    });
  }

  selectMembers(selectedMembers: any) {
    this.selectedItems = selectedMembers.length;
    this.selectedMembers = selectedMembers;
    this.$emit('selectedMembers', selectedMembers);
  }

  getBadgeVariant(status: string) {
    return ({
      active: 'success',
      deactivated: 'cherry',
      alumnus: 'cherry',
      lead: 'secondary',
    } as any)[status];
  }

  clearSelected() {
    this.membersTable.clearSelected();
  }

  selectAllRows() {
    this.membersTable.selectAllRows();
  }
}

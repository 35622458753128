<template>
  <div class="d-flex flex-column justify-content-center" v-if="loadingStudent">
    <hsLoading />
  </div>
  <div v-else>
    <template>
      <hsPageHeader
        :title="memberFullname"
        :back-text="$t('sparkmembers.members-details.title')"
        back-route="MembersList"
      >
        <template slot="actions">
          <hs-button variant="link" @click="() => $bvModal.show('delete-member-modal')">
            {{ $t('actions.exclude') }}
          </hs-button>
        </template>
      </hsPageHeader>

      <Tabs v-model="tab" :tabs="tabItems" />

      <hsConfirmModal
        id="delete-member-modal"
        icon="trash-alt"
        variant="cherry"
        hide-header
        :customTitle="$t('sparkmembers.members-list.delete-member-modal.title')"
        :description="$t('sparkmembers.members-list.delete-member-modal.description')"
        :ok-title="$t('sparkmembers.members-list.delete-member-modal.buttons.confirm')"
        :cancel-title="$t('sparkmembers.members-list.delete-member-modal.buttons.cancel')"
        @ok="onDelete"
      />
    </template>
  </div>
</template>

<script>
import { studentService } from '@/services';
import { mapState, mapActions } from 'vuex';
import { hsLoading } from '@/components';
import { hsConfirmModal } from '@/components';
import ToastHelper from '@/shared/helpers/toast';
import TrackingHelper from '@/shared/helpers/tracking';
import Tabs from '@/components/Tabs/index.vue';
import Resume from './components/Tab/Resume';
import Personal from './components/Tab/Personal';
import hsPageHeader from '@/components/_structures/PageHeader';

export default {
  data() {
    return {
      tab: 'resume',
      tabItems: [
        {
          id: 'resume',
          label: this.$t('sparkmembers.members-details.tab.resume'),
          component: Resume,
          permission: true,
        },
        {
          id: 'personal',
          label: this.$t('sparkmembers.members-details.tab.personal'),
          component: Personal,
          permission: true,
        },
      ],
      loadingStudent: true,
    };
  },
  components: {
    hsLoading,
    hsConfirmModal,
    Tabs,
    hsPageHeader,
  },
  computed: {
    ...mapState('member', {
      member: state => state.selectedMember,
    }),
    ...mapState('school', {
      selectedSchool: state => state.selectedSchool,
    }),
    memberFullname() {
      return `${this.member.first_name} ${this.member.last_name ? this.member.last_name : ''}`;
    },
  },
  methods: {
    ...mapActions('member', ['selectMember']),
    async onDelete() {
      this.loadingStudent = true;
      studentService
        .deleteStudent(this.member.id)
        .then(() => {
          this.trackingMemberDelete();
          ToastHelper.successMessage(this.$t('sparkmembers.members-details.toast.deleted'));
          this.selectMember({});
          this.$router.push({ name: 'MembersList' });
        })
        .catch(() => ToastHelper.dangerMessage(this.$t('sparkmembers.members-details.toast.error-deleted')))
        .finally(() => (this.loadingStudent = false));
    },
    trackingMemberDetailsOpened() {
      TrackingHelper.trackMemberDetailsOpened(this.selectedSchool.email, this.selectedSchool.id);
    },
    trackingMemberDelete() {
      TrackingHelper.trackMemberDeleted('Member Details Page', this.selectedSchool.email, this.selectedSchool.id, 1);
    },
  },
  watch: {
    tab() {
      this.$router.push({ query: { tab: this.tab } });
    },
  },
  mounted() {
    if (this.$route.query.tab) {
      this.tab = this.$route.query.tab;
    }
    studentService
      .getStudent(this.$route.params.id)
      .then(res => {
        this.selectMember(res.data);
      })
      .catch(() => {
        ToastHelper.dangerMessage(this.$t('sparkmembers.members-details.toast.not-found'));
        this.$router.push({ name: 'MembersList' });
      })
      .finally(() => (this.loadingStudent = false));

    this.trackingMemberDetailsOpened();
  },
  beforeDestroy() {
    if (this.$route.name !== 'AccessHistory') {
      this.selectMember({});
    }
  },
};
</script>
<style scoped lang="scss">
.back-button {
  &:hover {
    span {
      text-decoration: underline;
    }
  }
}
</style>

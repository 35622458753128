<template>
  <div>
    <hs-input
      :type="field.type"
      :placeholder="field.placeholder"
      :required="field.required"
      @blur="$event => $emit('input', $event.target.value)"
      :value="field.value"
    />
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div>
    <hs-date
      v-model="date"
      :placeholder="$t('date.format')"
      :label-no-date-selected="$t('date.format')"
      :name="`custom_fields[${field.slug}]`"
      :required="field.required"
      value-as-date
      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  computed: {
    date: {
      get() {
        return this.field.value ? dayjs(this.field.value).toDate() : null;
      },
      set(newDate) {
        this.$emit('input', newDate.toISOString());
      },
    },
  },
};
</script>









































































































































































import { Component, Ref, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import PageHeader from '@/components/_structures/PageHeader.vue';
import { hsLoading, hsConfirmModal, hsPremiumModal } from '@/components';
import MButton from '@/shared/components/MButton.vue';
import { productService, studentService, channelService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import TrackingHelper from '@/shared/helpers/tracking';
import NoFilteredMembersWarning from './components/NoFilteredMembersWarning.vue';
import MembersTable from './components/MembersTable.vue';
import Filters from '@/components/Filters/index.vue';
import Confirm from '@/shared/mixins/Confirm';
import PremiumBadge from '@/components/PremiumBadge/index.vue';
import { Member, Tag } from '@/types/members';
import { sortLocaleWithKey } from '@/libs/strings';
import { LocationSegmentProps, PaywallReasonSegmentProps } from '@/libs/paywall';
import { LocationMemberSegment } from '@/libs/member';
import _ from 'lodash';

const AuthModule = namespace('auth');
const MemberModule = namespace('member');
const FilterModule = namespace('filter');
const SchoolModule = namespace('school');

@Component({
  components: {
    hsLoading,
    PageHeader,
    Filters,
    PremiumBadge,
    MButton,
    hsConfirmModal,
    hsPremiumModal,
    NoFilteredMembersWarning,
    MembersTable,
    MembersSidebar: () => import('./components/MembersSidebar.vue'),
    NoMembersWarning: () => import('./components/NoMembersWarning.vue'),
    ImportMembersModal: () => import('./components/ImportMembersModal.vue'),
    MessageCreatorModal: () =>
      import('@/views/Messages/views/List/components/Tab/Messages/components/MessageCreatorModal.vue'),
    AssignProductModal: () => import('./components/AssignProductModal.vue'),
    DesactiveEnrollmentModal: () => import('./components/DesactiveEnrollmentModal.vue'),
    TagModal: () => import('./components/TagModal.vue'),
    AddTagModal: () => import('./components/AddTagModal.vue'),
    AutoPilotBanner: () => import('@/components/AutoPilotBanner.vue'),
  },
})
export default class MemberList extends Mixins(Confirm) {
  @MemberModule.Getter('getSortedMembersList') members!: Member[];

  @AuthModule.State loggedUser!: any;
  @MemberModule.State sidebar!: any;
  @MemberModule.State selectedMember!: Member;
  @FilterModule.State selectedFilters!: any;
  @SchoolModule.State selectedSchool!: any;

  @MemberModule.Action getMembers!: (params: any) => Promise<any>;
  @MemberModule.Action selectMember!: (member: Member | object) => void;
  @MemberModule.Action deleteMembers!: (members: Member[]) => void;
  @MemberModule.Action toggleSidebar!: (open: any) => void;
  @MemberModule.Action setProducts!: (products: any) => void;

  @Ref() membersTable!: MembersTable;

  selectedMembers: Member[] = [];
  desactiveMembers: any = {
    members: [],
    products: [],
  };
  sendMessageMembers: Member[] = [];
  assignProductMembers: Member[] = [];
  addTagMembers: Member[] = [];

  isLoading: boolean = false;
  isLoadingSelectAllMembers: boolean = false;

  currentPage: number = 1;
  perPage: number = 50;
  totalNumberOfMembers: number = 0;

  checkbox: boolean = true;
  showImportModal: boolean = false;
  showConfirmBox!: any;
  showAssignProductModal: boolean = false;
  showMyTagsModal: boolean = false;
  showAddTagModal: boolean = false;
  editTag: Tag | null = null;
  allSchoolProducts: any = [];

  filters: any[] = [
    {
      type: 'Main',
      key: 'full_name',
      label: this.$root.$t('sparkmembers.members-list.filters.full-name.label'),
      value: '',
    },
    {
      type: 'MultiSelect',
      key: 'status',
      label: this.$root.$t('sparkmembers.members-list.filters.status.label'),
      multiple: true,
      options: [
        {
          item: {
            label: this.$root.$t('sparkmembers.members-list.filters.status.options.active.label'),
            value: 'active',
          },
        },
        {
          item: {
            label: this.$root.$t('sparkmembers.members-list.filters.status.options.inactive.label'),
            value: 'alumnus',
          },
        },
      ],
      value: null,
    },
    {
      type: 'Date',
      key: 'enrolled_at%3E%3D',
      label: this.$root.$t('sparkmembers.members-list.filters.date.label'),
      format: { day: 'numeric', year: 'numeric', month: 'numeric' },
      value: null,
    },
    {
      type: 'MultiSelect',
      key: 'school_products_ids',
      label: this.$root.$t('sparkmembers.members-list.filters.products.label'),
      multiple: true,
      options: [],
      value: null,
    },
    {
      type: 'MultiSelect',
      key: 'category_id',
      label: this.$root.$t('sparkmembers.members-list.filters.tags.label'),
      multiple: true,
      options: [],
      value: null,
    },
  ];

  get showNoFilteredMembersWarning() {
    return !this.isLoading && !this.members.length && this.filters.some(f => f.value);
  }

  get showNoMembersWarning() {
    return !this.isLoading && !this.members.length && !this.filters.some(f => f.value);
  }

  get showDesactiveEnrollment() {
    const filterSchoolProducts = this.filters.find(filter => filter.key === 'school_products_ids').value;
    return filterSchoolProducts === null || filterSchoolProducts.length > 1;
  }

  get canImportMembers() {
    return this.$root.$FCL.canImportMembers();
  }

  get canAutomaticPilot() {
    return this.$root.$ACL.canAutomaticPilotPage();
  }

  get canSendMessages() {
    return this.$root.$ACL.canMessagesPage();
  }

  async created() {
    await this.mountMembersList(1);
    await this.getSchoolProducts(1);
    await this.getTags();
  }

  mounted() {
    this.$root.$on('onShowSendMessageMemberModal', this.openSendMessageModal);
    this.$root.$on('onShowAssignProductMemberModal', this.openAssignProductModal);
    this.$root.$on('onShowDesactiveMemberModal', this.desactiveMember);
    this.$root.$on('onShowDeleteMemberModal', this.removeMembers);
    this.$root.$on('onShowAddTagModal', this.openAddTagModal);
  }

  beforeDestroy() {
    this.closeSidebar();
  }

  async changePage(page: any) {
    await this.mountMembersList(page);
    this.currentPage = page;
  }

  async mountMembersList(page: number) {
    this.currentPage = page;
    this.selectedMembers = [];
    this.isLoading = true;

    const filters = this.parseFiltersToParams();

    try {
      const res = await this.getMembers([
        { key: 'page', value: page },
        { key: 'per_page', value: this.perPage },
        ...filters,
      ]);

      this.trackingMemberSearch(res.total_count, filters.map(filter => filter.key));

      this.totalNumberOfMembers = res.total_count;
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('sparkmembers.members-list.mount-members-error'));
    } finally {
      this.isLoading = false;
    }

    this.closeSidebar();
  }

  parseFiltersToParams(): Array<{ key: string; value: string }> {
    const filterToValue = (f: any) => (f.type === 'MultiSelect' || f.type === 'Date' ? f.value.join() : f.value);
    return this.filters.reduce((acc, filter) => {
      Boolean(filter.value) && acc.push({ key: filter.key, value: filterToValue(filter) });
      return acc;
    }, []);
  }

  async getSchoolProducts(page: number = 1) {
    try {
      const { school_products, current_page, total_pages } = await productService.getAll([
        { key: 'page', value: page },
        { key: 'per_page', value: 50 },
        { key: 'request_attributes', value: 'library_resource' },
      ]);

      this.allSchoolProducts = _.unionBy(this.allSchoolProducts, school_products, 'id');
      if (current_page < total_pages) {
        this.getSchoolProducts(current_page + 1);
      } else {
        this.setProducts(this.allSchoolProducts);
        this.mountFilterOptions(this.allSchoolProducts, 'school_products_ids');
      }
    } catch (error) {
      new Error(error);
    }
  }

  async getTags() {
    try {
      const query = [
        { key: 'page', value: 1 },
        { key: 'per_page', value: 50 },
        { key: 'sort', value: 'id' },
        { key: 'direction', value: 'desc' },
      ];

      const { categories } = await studentService.getUserCategories(query);

      this.mountFilterOptions(categories, 'category_id');
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('sparkmembers.members-list.filters.tags.toast-error'));
    }
  }

  mountFilterOptions(items: any[], filterType: string) {
    const options = items.map(item => {
      return { item: { label: item.title || item.name, value: item.id } };
    });

    const sortItems = sortLocaleWithKey(options, 'item.label');

    const filter = this.filters.find(filter => filter.key === filterType);
    this.$set(filter, 'options', sortItems);
  }

  async showConfirmBoxToDeleteMembers() {
    return await this.showConfirmBox({
      contentTitle: this.$t('sparkmembers.members-list.delete-member-modal.title'),
      contentDescription: this.$t('sparkmembers.members-list.delete-member-modal.description'),
      okTitle: this.$t('sparkmembers.members-list.delete-member-modal.buttons.confirm'),
      cancelTitle: this.$t('sparkmembers.members-list.delete-member-modal.buttons.cancel'),
      variant: 'cherry',
      icon: 'info-circle',
    });
  }

  async removeMembers(members: Member[]) {
    const confirm = await this.showConfirmBoxToDeleteMembers();

    if (confirm) {
      try {
        this.isLoading = true;
        await this.deleteMembers(members);
        this.closeSidebar();
        this.selectedMembers = [];
        ToastHelper.successMessage(this.$t('sparkmembers.members-list.delete-member-modal.toast-success'));
        this.trackingMemberDelete(members.length);
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.members-list.delete-member-modal.toast-error'));
      } finally {
        this.isLoading = false;
      }
    }
  }

  closeSidebar() {
    this.toggleSidebar({ open: false });
    this.selectMember({});
  }

  clearSelectedMembers() {
    this.selectedMembers = [];

    this.membersTable.clearSelected();
    const checkHead: any = this.membersTable.$el.querySelector('thead tr .custom-control-input');
    checkHead.checked = false;

    const checksBody: any = this.membersTable.$el.querySelectorAll('tbody .custom-control-input');
    checksBody.forEach((el: any) => (el.disabled = false));
  }

  openSendMessageModal(members: Member[]) {
    this.sendMessageMembers = members;
    this.$bvModal.show('message-creator-modal');
  }

  async createMessage({ channel, message }: any) {
    try {
      const { id } = await channelService.create(channel);
      await channelService.createMessage({ ...message, channel_id: id });
      this.trackingMessageSent(channel.user_ids.length, message);
      ToastHelper.successMessage(this.$t('messages.views.list.components.message-creator-modal.toast.success'));
    } catch (error) {
      ToastHelper.dangerMessage(
        this.$t('messages.views.list.components.message-creator-modal.toast.error-send-mensage')
      );
    }
  }

  async desactiveMember(data?: any) {
    if (data && data.members && data.members.length) {
      this.desactiveMembers = {
        members: data.members,
        products: sortLocaleWithKey(data.products, 'title'),
      };
    } else {
      const products: any = [];

      this.selectedFilters.forEach(filters =>
        filters
          .filter(filter => filter.key === 'Produtos')
          .map(item => {
            products.push({
              id: item.value,
              title: item.label,
            });
          })
      );

      this.desactiveMembers = {
        members: this.selectedMembers,
        products: sortLocaleWithKey(products, 'title'),
      };
    }

    await this.$nextTick();
    this.$bvModal.show('desactive-enrollment-modal');
  }

  openAssignProductModal(members: Member[], open: boolean = true) {
    this.showAssignProductModal = open;
    this.assignProductMembers = members;
  }

  toggleModalImportMembers(open: boolean = true) {
    const enabled = this.canImportMembers;

    this.showImportModal = open && enabled;

    if (!enabled) {
      this.trackingPaywallDisplayed();
      this.$bvModal.show('premium-modal');
    }
  }

  openMyTagsModal() {
    this.editTag = null;
    this.showMyTagsModal = true;
    this.showAddTagModal = false;
  }

  openAddTagModal(members: Member[]) {
    this.showAddTagModal = true;
    this.addTagMembers = members;
  }

  closeCreateTagModal(hasEditTag: boolean) {
    hasEditTag && this.mountMembersList(1);
    this.showMyTagsModal = false;
  }

  requestPremiumFeature() {
    this.$router.push({ name: 'MyAccountPlans' });
    this.$bvModal.hide('premium-modal');
  }

  trackingPaywallDisplayed() {
    TrackingHelper.trackPaywallDisplayed(
      LocationSegmentProps.MEMBERS_IMPORT,
      PaywallReasonSegmentProps.MEMBERS_IMPORT,
      this.selectedSchool.email,
      this.selectedSchool.id,
      this.selectedSchool.id
    );
  }

  trackingMemberSearch(member_count: number, filters: string[]) {
    filters.length &&
      TrackingHelper.trackMemberSearched(this.loggedUser.email, this.selectedSchool.id, member_count, filters);
  }

  trackingMessageSent(member_count: number, message: { text: string }) {
    const anexo = message.text.search('<img') !== -1;
    TrackingHelper.trackMemberMessageSent(
      LocationMemberSegment.MEMBERS_LIST_PAGE,
      this.loggedUser.email,
      this.selectedSchool.id,
      member_count,
      anexo
    );
  }

  trackingMemberDelete(member_count: number) {
    TrackingHelper.trackMemberDeleted(
      LocationMemberSegment.MEMBERS_LIST_PAGE,
      this.loggedUser.email,
      this.selectedSchool.id,
      member_count
    );
  }
}
